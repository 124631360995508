<template>
  <div class="locations-list">
    <screen-header
      class="locations-list__header"
      :title="
        $tc('locations.list.title', locationsPagination.nbResults, {
          brand: currentClient.name,
          count: locationsPagination.nbResults,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
      :rightSectionWrap="true"
    >
      <!-- MANAGE BUTTON -->
      <skeleton-input v-if="isLoading && isUserAuthorized(['sa'])" margin-left="24px" />
      <v-btn
        v-else-if="isUserAuthorized(['sa'])"
        rounded
        color="primary"
        large
        class="locations-list__header__popover--inner__manage"
        @click="goToManage"
      >
        <v-icon class="tw-mr-2">{{ icons.mdiCog }}</v-icon>
        {{ $t('locations.list.manage.cta') }}
      </v-btn>

      <!-- PUBLISH BUTTON -->
      <skeleton-input v-if="isLoading" margin-left="24px" />
      <v-popover
        v-else
        popover-inner-class="locations-list__header__popover--inner"
        popover-arrow-class="tooltip-arrow locations-list__header__popover--arrow"
        placement="bottom"
        :trigger="isMobile ? 'click' : 'hover'"
        :disabled="isPublishing || !currentFrontOfficeIsSet()"
      >
        <div class="tw-flex">
          <ui-button
            class="locations-list__header__popover--inner__publish"
            button="cta"
            icon="publish"
            variant="data"
            :label="$t('frontOffices.id.header.publish.label')"
            :disabled="
              isUserAuthorized(['group_admin']) ||
              isPublishing ||
              !currentFrontOfficeIsSet() ||
              frontOfficeRulesDisabledPublish
            "
            :loader-active="isPublishing"
            @click="!isMobile && publish()"
          />
        </div>

        <template slot="popover">
          <div class="locations-list__header__popover--inner__sync">
            {{ $t('frontOffices.id.header.tooltip.sync') }}
            <div class="locations-list__header__popover--inner__sync__date">
              {{ dateLastUpdate }}
            </div>
          </div>
          <div class="locations-list__header__popover--inner__time">
            {{ $t('frontOffices.id.header.tooltip.estimatedTime') }}
            {{ estimatedTime + $t('frontOffices.id.header.tooltip.seconds') }}
          </div>
          <div v-if="isMobile" class="locations-list__header__popover--inner__mobileValidation">
            <span
              @click="publish"
              class="locations-list__header__popover--inner__mobileValidation--validate backoffice-icons"
              >success</span
            >
            <span
              v-close-popover
              class="locations-list__header__popover--inner__mobileValidation--close backoffice-icons"
              >error</span
            >
          </div>
        </template>
      </v-popover>

      <!-- EXPORT BUTTON -->
      <skeleton-input v-if="isLoading" margin-left="24px" />
      <div class="locations-list__header__cta" v-else-if="isUserAuthorized(['sa', 'agency', 'brand_admin'])">
        <v-menu offset-y left max-width="300" transition="slide-y-reverse-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn rounded color="primary" large class="tw-px-4" v-bind="attrs" v-on="on">
              <v-icon class="tw-mr-2">{{ icons.mdiDownload }}</v-icon>
              {{ $t('locations.list.export.cta') }}
              <v-icon class="tw-ml-2">{{ icons.mdiMenuDown }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="downloadLocationCsv">
              <v-list-item-content>
                <v-list-item-title>{{ $t('locations.list.export.locations') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="downloadEohCsv">
              <v-list-item-content>
                <v-list-item-title>{{ $t('locations.list.export.eoh') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="downloadUrlsCsv">
              <v-list-item-content>
                <v-list-item-title>{{ $t('locations.list.export.urls') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- FRONTOFFICE DROPDOWN -->
      <skeleton-input v-if="isLoading" margin-left="24px" />
      <front-office-select
        v-else-if="!frontofficeIsUnique"
        class="locations-list__header__cta"
        :front-offices="frontOffices"
        :label="$t('locations.list.filters.selectFrontOffice')"
        @onUpdateFilters="onUpdateFiltersFO"
        @frontofficeSelected="frontofficeSelected"
      ></front-office-select>
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <ui-data-search
              :element-fixed="elementFixed"
              :scroll-direction="scrollDirection"
              :is-mobile="isMobile"
              :is-tablet="isTablet"
              :is-loading="isLoading"
              :is-scrolling="isScrolling"
              :creation-roles="[userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency]"
              view="locations"
              ref="fixedElement"
              :withAddButton="true"
              @add="addLocation"
              @search="onSearch"
              :with-search="true"
              :with-filter="true"
            >
              <template v-slot:filterSlot>
                <locations-list-filters
                  :client-list-groups="clientListgroups"
                  :product-locator-status="currentClient.productLocatorStatus"
                  @onUpdateFilters="onUpdateFilters($event, 'filters')"
                />
              </template>
            </ui-data-search>
            <div class="locations-list__list">
              <ui-data-list
                :items="locationsMobile"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :no-more-data="noMoreData"
                :pagination="locationsPagination"
                infinite-scroll
                @loadItems="
                  loadLocations(
                    { filters: stringifiedSearchFilters, ...$event, page: locationsPagination.page + 1 },
                    false,
                    true
                  )
                "
                @click="goToLocation({ row: { id: $event.id } })"
              >
                <template v-slot:skeleton>
                  <div class="locations-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="locations-list__list__content">
                    <div class="locations-list__list__content__name">
                      {{ slotProps.item.name }}
                    </div>
                    <address class="locations-list__list__content__address">
                      {{ slotProps.item.address }}, {{ slotProps.item.postal_code }} {{ slotProps.item.city }}
                    </address>
                  </div>
                  <span
                    class="locations-list__list__status backoffice-icons"
                    :class="[`locations-list__list__status--${slotProps.item.status}`]"
                  >
                    <template
                      v-if="
                        slotProps.item.status === '1' ||
                        slotProps.item.status === '10' ||
                        slotProps.item.status === '20'
                      "
                    >
                      success
                    </template>
                    <template v-else>error</template>
                  </span>
                </template>
              </ui-data-list>
            </div>
            <div class="locations-list__table">
              <ui-data-table
                :columns="columns"
                :rows="locations"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :pagination="locationsPagination"
                :default-api-call-per-page="100"
                @loadItems="loadLocations({ filters: stringifiedSearchFilters, ...$event }, true, true)"
                @onCellClick="goToLocation"
              >
                <template slot="data-table-row" slot-scope="props">
                  <router-link
                    class="locations-list__table__link"
                    :to="{ name: 'Location', params: { id: props.row['id'], currentTab: 'general' } }"
                  />
                  <span v-if="props.column.field === 'name'">
                    <div class="locations-list__table__block">
                      <div class="locations-list__table__block__content">
                        <div class="locations-list__table__block__content__name">
                          {{ props.row['name'] }}
                        </div>
                        <div v-if="props.row['company']" class="locations-list__table__block__content__company-name">
                          {{ props.row['company'] }}
                        </div>
                        <address class="locations-list__table__block__content__address">
                          {{ props.row['street1'] }}, {{ props.row['postalCode'] }}
                          {{ props.row['city'] }}
                        </address>
                      </div>
                    </div>
                  </span>
                  <span v-if="props.column.field === 'group'"> {{ props.row['group'] }} </span>
                  <span v-if="props.column.field === 'externalId'"> {{ props.row['externalId'] }} </span>
                  <span v-if="props.column.field === 'id'"> {{ props.row['id'] }} </span>
                  <span v-if="props.column.field === 'status'">
                    <span
                      class="locations-list__table__status backoffice-icons"
                      :class="[`locations-list__table__status--${props.row['status']}`]"
                    >
                      <template
                        v-if="
                          props.row['status'] === '1' || props.row['status'] === '10' || props.row['status'] === '20'
                        "
                      >
                        success
                      </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mdiDownload, mdiMenuDown, mdiCog } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import FrontOfficeSelect from '@/components/FrontOffice/Select.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'
import LocationsListFilters from './ListFilters'
import { formatedDate } from '@/utils/date.util'
import { downloadFile } from '@/utils/download.util'
import { isUserAuthorized } from '@/config/permissions.config'
import { getLocationCsv, getEohCsv, getUrlsCsv } from '@/services/location.service'
import { getClientGroups } from '@/services/location.service'

export default {
  name: 'LocationsList',
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    UiButton,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    FrontOfficeSelect,
    SkeletonIcon,
    SkeletonInput,
    LocationsListFilters,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isUserAuthorized,
      search: '',
      stringifiedSearchFilters: '',
      searchFilters: {},
      isLoading: false,
      isScrolling: false,
      isUpdating: false,
      isPublishing: false,
      isDownload: false,
      noMoreData: false,
      totalRows: 0,
      userRoles,
      currentFrontOffice: () => {},
      frontofficeIsUnique: false,
      clientListgroups: [],
      icons: {
        mdiDownload,
        mdiMenuDown,
        mdiCog,
      },
    }
  },
  async created() {
    this.isLoading = true
    await this.getFrontOffices({})

    const { data } = await getClientGroups(this.currentClient.id)

    this.clientListgroups = data.results.map(group => ({
      ...group,
      name: group.value ? group.value : this.$t('locations.list.filters.group.noGroup'),
    }))

    if (this.frontOffices.length === 1) {
      this.currentFrontOffice = this.frontOffices[0]
      this.frontofficeIsUnique = true
      await this.getFrontOffice({ frontOfficeId: this.frontOffices[0].id })
    }
    this.isLoading = false
  },
  watch: {
    search() {
      this.loadLocations({ filters: this.stringifiedSearchFilters }, true, true)
    },
    stringifiedSearchFilters() {
      this.loadLocations({ filters: this.stringifiedSearchFilters }, true, true)
    },
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
      locationsMobile: state => state.location.locationsMobile,
      locationsPagination: state => state.location.locationsPagination,
      currentClient: state => state.client.currentClient,
      frontOffices: state => state.frontoffice.frontOffices,
    }),
    frontOfficeRulesDisabledPublish() {
      return this.currentFrontOffice?.algoliaRules?.publish === false
    },
    noData() {
      return this.isLoading || this.noMoreData
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('locations.list.breadcrumb'),
          route: {
            name: 'Locations',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('locations.list.column.location'),
          field: 'name',
        },
        {
          label: this.$t('locations.list.column.externalId'),
          field: 'externalId',
        },
        {
          label: this.$t('locations.list.column.id'),
          field: 'id',
          type: 'number',
        },
        {
          label: this.$t('locations.list.column.group'),
          field: 'group',
        },
        {
          label: this.$t('locations.list.column.status'),
          field: 'status',
        },
      ]
    },
    estimatedTimeInSecond() {
      const locationPerLang = this.publishInfo?.entries * this.locales?.length
      let seconds
      if (!locationPerLang || locationPerLang <= 100) {
        seconds = 5
      } else if (locationPerLang <= 400) {
        seconds = 9
      } else if (locationPerLang <= 1000) {
        seconds = 20
      } else if (locationPerLang <= 4000) {
        seconds = 30
      } else if (locationPerLang <= 16000) {
        seconds = 90
      } else if (locationPerLang <= 32500) {
        seconds = 150
      } else {
        seconds = 180
      }
      return seconds
    },
    dateLastUpdate() {
      return this.currentFrontOffice?.lastAlgoliaImport
        ? formatedDate(this.currentFrontOffice.lastAlgoliaImport, 'LL HH:mm:ss', this.$i18n.locale)
        : this.$t('frontOffices.id.header.tooltip.neverPublish')
    },
    estimatedTime() {
      const minutes = Math.floor(this.estimatedTimeInSecond / 60)
      const seconds = this.estimatedTimeInSecond - minutes * 60

      return `
        ${minutes ? `${minutes} ${this.$t('frontOffices.id.header.tooltip.minutes')}` : ''}
        ${seconds ? Math.round(seconds) : '00'}
      `
    },
  },
  methods: {
    ...mapActions({
      getLocations: 'location/getLocations',
      getFrontOffice: 'frontoffice/getFrontOffice',
      getFrontOffices: 'frontoffice/getFrontOffices',
      publishFrontOffice: 'frontoffice/publishFrontOffice',
    }),
    currentFrontOfficeIsSet() {
      return typeof this.currentFrontOffice === 'object'
    },
    goToLocation(e) {
      this.$router.push({ name: 'Location', params: { id: e.row.id } })
    },
    onUpdateFilters(stringifiedFilters) {
      this.searchFilters.filters = stringifiedFilters
      this.stringifiedSearchFilters = this.getStringifiedSearchFilters()
    },
    onUpdateFiltersFO(stringifiedFilters) {
      this.searchFilters.fo = stringifiedFilters
      this.stringifiedSearchFilters = this.getStringifiedSearchFilters()
    },
    getStringifiedSearchFilters() {
      return Object.values(this.searchFilters)
        .map(filter => filter)
        .join(',')
    },
    frontofficeSelected(frontoffice) {
      this.currentFrontOffice = frontoffice
      frontoffice ? this.getFrontOffice({ frontOfficeId: frontoffice.id }) : null
    },
    onSearch(value) {
      this.search = value
    },
    loadLocations(tableParams = null, resetLocations = false, updating = false) {
      if (updating) {
        this.isUpdating = true
        this.isScrolling = true
      }
      const search = this.search
      this.getLocations({ tableParams, resetLocations, search })
        .then(() => {
          this.totalRows = this.locationsPagination.nbResults
          this.isScrolling = false
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isScrolling = false
          this.isUpdating = false
        })
    },
    addLocation() {
      this.$router.push({ name: 'LocationCreate' })
    },
    publish() {
      this.isPublishing = true
      this.publishFrontOffice()
        .then(() => {
          this.isPublishing = false
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name, 'publish')
        })
        .catch(() => {
          this.isPublishing = false
          this.notificationError()
        })
    },
    goToManage() {
      this.$router.push({ name: 'LocationsManager' })
    },
    async downloadLocationCsv() {
      this.isDownload = true
      getLocationCsv(this.currentClient.id, this.stringifiedSearchFilters)
        .then(response => {
          const fileName = response.filename || `export_locations_${formatedDate('now', 'DDMMYYYY')}`
          const fileExtension = response.extension || 'csv'
          downloadFile(response, `${fileName}.${fileExtension}`)
          this.isDownload = false
        })
        .catch(() => {
          this.isDownload = false
          this.notificationError()
        })
    },
    async downloadEohCsv() {
      this.isDownload = true
      try {
        const response = await getEohCsv(this.currentClient.id)
        const fileName = response.filename || `export_eoh_${formatedDate('now', 'DDMMYYYY')}`
        const fileExtension = response.extension || 'csv'
        downloadFile(response, `${fileName}.${fileExtension}`)
      } catch {
        this.notificationError()
      }
      this.isDownload = false
    },
    async downloadUrlsCsv() {
      this.isDownload = true
      try {
        const response = await getUrlsCsv(this.currentClient.id)
        const fileName = response.filename || `export_urls_${formatedDate('now', 'DDMMYYYY')}`
        const fileExtension = response.extension || 'csv'
        downloadFile(response, `${fileName}.${fileExtension}`)
      } catch {
        this.notificationError()
      }
      this.isDownload = false
    },
  },
}
</script>

<style lang="scss">
.locations-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__popover {
      display: flex;
      &--arrow {
        border-color: map-get($generic-color-variants, 'success');
      }

      &--inner {
        box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: white;
        padding: 0 0 4px 0;
        max-width: 250px;
        line-height: 1.6;
        font-size: 0.79rem;

        &__sync {
          margin-bottom: 5px;
          background-color: map-get($generic-color-variants, 'success');
          padding: 4px 0;
          text-align: center;
          color: white;
          font-weight: 600;

          &__date {
            padding: 0 5px;
            font-weight: 400;
          }
        }

        &__location {
          padding: 0 $gutter-mobile;
          &__not-publish {
            color: map-get($generic-color-variants, 'blue-cerulean');
          }
        }

        &__time {
          padding: 0 $gutter-mobile;
          color: map-get($generic-color-variants, 'warning');
        }

        &__mobileValidation {
          text-align: center;
          &--validate {
            color: map-get($generic-color-variants, 'success');
            font-size: 1.9rem;
          }

          &--close {
            margin-left: 9px;
            color: map-get($generic-color-variants, 'error');
            font-size: 1.9rem;
          }
        }

        &__manage {
          margin-right: 24px;
        }
      }
    }

    &__publish {
      position: fixed;
      right: $gutter-mobile;
      bottom: $gutter-mobile;
      z-index: 2;

      @media (min-width: $screen-sm) {
        position: relative;
        right: initial;
        bottom: initial;
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__popoverFilter {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
    }
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__select {
      margin-left: 7px;
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__name {
        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }
    &__select {
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__status {
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__address {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-sm;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'data');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
