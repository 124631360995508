<template>
  <div class="front-offices-select">
    <v-select
      :items="frontOffices"
      hide-details
      dense
      height="44px"
      outlined
      solo
      flat
      background-color="transparent"
      rounded
      return-object
      item-value="id"
      item-text="name"
      clearable
      :label="label"
      :placeholder="label"
      :prepend-inner-icon="icons.mdiDomain"
      @change="sendFrontOffice"
    >
    </v-select>
  </div>
</template>

<script>
import { mdiDomain } from '@mdi/js'
import { onScroll } from '@/mixins/scroll.mixin'

const getStringifiedFrontOffices = value => (value && value.tag && value.tag.length > 0 ? `tags:${value.tag}` : '')

export default {
  name: 'FrontOfficeSelect',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    frontOffices: {
      type: Array,
      required: true,
    },
  },
  mixins: [onScroll],
  data: () => {
    return {
      toggle: false,
      icons: {
        mdiDomain,
      },
      selectedfrontOffice: '',
    }
  },
  computed: {
    stringifiedSearch() {
      return getStringifiedFrontOffices(this.selectedfrontOffice)
    },
  },
  methods: {
    sendFrontOffice(newFo) {
      this.selectedfrontOffice = newFo
      this.$emit('onUpdateFilters', this.stringifiedSearch)
      this.$emit('frontofficeSelected', this.selectedfrontOffice)
    },
  },
}
</script>

<style lang="scss">
.front-offices-select {
  max-width: 250px;
}
</style>
